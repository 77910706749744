import { Language } from "@/interfaces/Language";
import { Mode } from "@/interfaces/Mode";
import { Profile } from "@/interfaces/Profile";
import { ProfileSettings } from "@/interfaces/ProfileSettings";
import { reactive } from "vue";
import * as http from '@/http';

const store = {
    debug: true,

    state: reactive({
        currentProfile: {} as Profile,
        currentSettingsForProfile: {} as ProfileSettings,
        focusOnContact: false,
        focusOnImprint: false,
        linkFromOtherView: false,
        showInfoOfSkill: 0,
        showInfoOfEntry: 0,
        selectedLanguage: Language.EN,
        selectedMode: Mode.LIGHT,
        articleId: 1,
    }),

    async setCurrentProfile(newValue: any){
        if(this.debug) console.log('setCurrentProfile triggered with: ', newValue);

        this.state.currentProfile = newValue;

        const projects = await http.getPostsByAuthorId();

        this.state.currentProfile.posts = projects;
    },

    setCurrentSettingsForProfile(newValue: any){
        if(this.debug) console.log('setCurrentSettingsForProfile triggered with: ', newValue);

        this.state.currentSettingsForProfile = newValue;
    },

    setFocusOnContact(newValue: any){
        if(this.debug) console.log('setFocusOnContact triggered with: ', newValue);

        if(newValue === true){
            this.state.focusOnImprint = false;
        }

        this.state.focusOnContact = newValue;
    },

    setFocusOnImprint(newValue: any){
        if(this.debug) console.log('setFocusOnImprint triggered with: ', newValue);

        if(newValue === true){
            this.state.focusOnContact = false;
        }

        this.state.focusOnImprint = newValue;
    },

    setLinkFromOtherView(newValue: any){
        if(this.debug) console.log('setLinkFromOtherView triggered with: ', newValue);

        this.state.linkFromOtherView = newValue;
    },

    setShowInfoOfSkill(newValue: any){
        if(this.debug) console.log('setShowInfoOfSkill triggered with: ', newValue);

        this.state.showInfoOfSkill = newValue;
    },

    resetShowInfoOfSkill(){
        if(this.debug) console.log('showInfoOfSkill reset.');

        this.state.showInfoOfSkill = 0;
    },

    setShowInfoOfEntry(newValue: any){
        if(this.debug) console.log('setShowInfoOfEntry triggered with: ', newValue);

        this.state.showInfoOfEntry = newValue;
    },

    resetShowInfoOfEntry(){
        if(this.debug) console.log('showInfoOfEntry reset.');

        this.state.showInfoOfEntry = 0;
    },

    setSelectedLanguage(newValue: Language) {
        if(this.debug) console.log('setSelectedLanguage triggered with: ', newValue);

        this.state.selectedLanguage = newValue;
    },

    resetSelectedLanguage() {
        if(this.debug) console.log('selected language reset to EN.');

        this.state.selectedLanguage = Language.EN;
    },

    setSelectedMode(newValue: Mode) {
        if(this.debug) console.log('setSelectedMode triggered with: ', newValue);

        this.state.selectedMode = newValue;
    },

    resetSelectedMode() {
        if(this.debug) console.log('selected mode reset to LIGHT.');

        this.state.selectedMode = Mode.LIGHT;
    },

    setArticleId(newValue: number){
        if(this.debug) console.log('Set ArticleId to: ', newValue);

        this.state.articleId = newValue;
    }
}

export default store;