import { Post, TimelineEntry } from '@/interfaces/Profile';
import axios from 'axios';

export const prodBackend = "https://strapi.levt.online/";
const backendAddress = prodBackend.substr(0, prodBackend.length - 1);

const userId = 1;

const backend = axios.create({
    baseURL: prodBackend,
    timeout: 0,
});

function replaceURLforIcons(profilePart: []) {
    profilePart.forEach((item:any) => {
        item.icon.url = backendAddress + item.icon.url;
    });
    return profilePart;
}

function replaceURLforSideImage(profilePart: []) {
    profilePart.forEach((item: any) => {
        item.sideimage.url = backendAddress + item.sideimage.url;
    })
    return profilePart;
}

function replaceURLForPicture(item: any){
    item.url = backendAddress + item.url;
    return item;
}

function replaceForPostsMedia(posts: []) {
    posts.forEach((post: any) => {
        post.thumbnail.url = backendAddress + post.thumbnail.url;
        post.media = replacePostMedia(post.media);
    })
    return posts;
}

function replacePostMedia(media: []) {
    if(media.length > 0) {
        media.forEach((el: any) => {
            el.url = backendAddress + el.url;
        });
    }

    return media;
}

function replacePostDownloadables(downloadables: []) {
    if(downloadables.length > 0){
        downloadables.forEach((el: any) => {
            el.url = backendAddress + el.url;
        });
    }

    return downloadables;
}

function getBothTimelineEntries(educations: [], professions: []) {
    let allTimelineEntries = Array();
    let entryId = 0;

    educations.forEach((education: TimelineEntry) => {
        entryId++;
        education.id = entryId;
        education.education = true;
        education.thumbnail = replaceURLForPicture(education.thumbnail);
        allTimelineEntries.push(education);
    });

    professions.forEach((profession: TimelineEntry) => {
        entryId++;
        profession.id = entryId;
        profession.education = false;
        profession.thumbnail = replaceURLForPicture(profession.thumbnail);
        allTimelineEntries.push(profession);
    });

    allTimelineEntries = allTimelineEntries.sort((a,b) => (a.fromdate > b.fromdate) ? -1 : 1);

    return allTimelineEntries;
}

export async function getProfileById() {
    const response = await backend.get("/profiles/"+userId);
    const profile = response.data;
    profile.picture = replaceURLForPicture(profile.picture);
    profile.softskills = replaceURLforIcons(profile.softskills);
    profile.softskills = replaceURLforSideImage(profile.softskills);
    profile.interests = replaceURLforIcons(profile.interests);
    profile.contacts = replaceURLforIcons(profile.contacts);
    profile.hardskills = replaceURLforIcons(profile.hardskills);
    profile.posts = replaceForPostsMedia(profile.posts);
    profile.allTimelineEntries = getBothTimelineEntries(profile.educations, profile.professions);
    return profile;
}

export async function getNewestImprint() {
    const response: any = await backend.get("/impressums/");
    const imprint = response.data[0];
    return imprint;
}

export async function getAllPosts(): Promise<Post[]> {
    const response = await backend.get("/posts/");
    let posts = response.data;
    posts = replaceForPostsMedia(posts);
    return posts;
}

export async function getPostById(postId: number) {
    const response = await backend.get("/posts/" + postId);
    const post = response.data;
    post.thumbnail = replaceURLForPicture(post.thumbnail);
    post.media = replacePostMedia(post.media);
    post.hardskills = replaceURLforIcons(post.hardskills);
    post.downloadables = replacePostDownloadables(post.downloadables);
    return post;
}

export async function getPostsBySkillId(skillId: number) {
    const response = await backend.get("/hardskills/" + skillId);
    let posts = response.data.posts;
    posts = replaceForPostsMedia(posts);
    return posts;
}

export async function getPostsByAuthorId() {
    const allPosts: Post[] = await getAllPosts();
    const userPosts = allPosts.filter(post => post.author.id === userId);
    return userPosts;
}

export async function getSettingsForProfileSite() {
    let response = await backend.get("/activeprofiles/1");
    const activeProfile = response.data.activeprofile;
    response = await backend.get("/profilesettings/" + activeProfile.id);
    const settings = response.data;
    return settings;
}