<template>
  <div ref="app">
    <router-view />
  </div>
    
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import * as http from '@/http';
import * as settings from '@/utils/settings';

@Options({
  mounted: function (){
    store.resetSelectedLanguage();
    store.resetSelectedMode();
    this.getActiveSettingsProfile();
  },
  methods: {
    async getActiveSettingsProfile() {
      const settingsProfile = await http.getSettingsForProfileSite();
      store.setCurrentSettingsForProfile(settingsProfile);
      const app = this.$refs.app;
      settings.setColorStyles(app, store.state.currentSettingsForProfile);
    },
  }
})
export default class App extends Vue {}
</script>


<style lang="scss">
@import '@/main.scss';

body {
  margin: 0;
}

h1 {
  margin: 0;
  font-family: $font-main;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
}

h2 {
  margin: 0;
  font-family: $font-main;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
}

h3 {
  margin: 0;
  margin-bottom: 5px;
  font-family: $font-main;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
}

h4 {
  font-family: $font-main;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

h5 {
  margin: 0;
  font-family: $font-main;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

p {
  font-family: $font-main;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--text-color);
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: var(--text-color);
}

#nav a.router-link-exact-active {
  color: var(--highlight-color);
}

.interest-box-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
}

.display-at-md {
  display: none;
}

.display-before-md {
  display: unset;
}

@media screen and (max-width: $break-xs) {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
  }
}

@media screen and (min-width: $break-md) {
  .display-at-md {
    display: unset;
  }

  .display-before-md {
    display: none;
  }

  .interest-box-wrapper {
    margin-top: 20px;
  }
}

</style>
