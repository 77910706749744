import ColorStyles from '@/interfaces/ColorStyles';
import * as constants from '@/utils/constants';

export function elementIsFirst(elOne: string){
    return elOne === constants.FIRST;
}

export function elementIsSecond(element: string){
    return element === constants.SECOND;
}

export function elementIsThird(element: string){
    return element === constants.THIRD;
}

export function elementIsLast(element: string){
    return element === constants.LAST;
}

export function setColorStyles(ref: HTMLBodyElement,colors: ColorStyles) {
    ref.style.setProperty('--background-light', colors.backgroundlight);
    ref.style.setProperty('--background-middle', colors.backgroundmiddle);
    ref.style.setProperty('--background-dark', colors.textcolor);
    ref.style.setProperty('--text-color', colors.textcolor);
    ref.style.setProperty('--text-color-light', colors.backgroundlight);
    ref.style.setProperty('--highlight-color', colors.highlightcolor);
}